import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon'; // svg组件

// register globally
Vue.component('svg-icon', SvgIcon);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};
var req = require.context('./svg', false, /\.svg$/);
requireAll(req);