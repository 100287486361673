import _extends from 'babel-runtime/helpers/extends';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';

export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger
  },
  computed: _extends({}, mapGetters(['sidebar', 'avatar', 'name'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('ToggleSideBar');
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    }
  }
};