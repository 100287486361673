var base_url = window.location.origin;
export default {
  //后管接口
  // 登录
  login: base_url + '/api/login',
  // 登录
  loginOut: base_url + '/monitor/logininfor/logout',
  // 上传
  uploadFile: base_url + '/common/upload',
  // 上传
  downloadFile: base_url + '/common/download',
  // 角色列表
  roleList: base_url + '/system/role/list',
  // 新增保存角色
  addRole: base_url + '/system/role/add',
  // 修改保存角色
  editRole: base_url + '/system/role/edit',
  // 删除角色
  deleteRole: base_url + '/system/role/remove',
  // 用户列表
  userList: base_url + '/system/user/list',
  // 新增保存用户
  addUser: base_url + '/system/user/add',
  // 修改保存用户
  editUser: base_url + '/system/user/edit',
  // 删除用户
  deleteUser: base_url + '/system/user/remove',
  // 修改密码
  resetPwd: base_url + '/system/user/resetPwd',
  // 查询项目配置列表
  projectList: base_url + '/system/info/list',
  // 查询项目配置列表
  updateApplyVote: base_url + '/system/record/updateApplyVote',
  // 查编辑项目配置列表
  editProject: base_url + '/system/info/edit',
  // 查询菜单
  queryMenuTreeData: base_url + '/system/menu/menuTreeData',
  // 系统所有菜单权限的
  queryAllMenuTreeData: base_url + '/system/menu/allMenuTreeData',
  // 加载角色菜单列表树
  queryRoleMenuTreeData: base_url + '/system/menu/roleMenuTreeData',
  // 保存角色分配数据权限
  saveAuthDataScope: base_url + '/system/role/authDataScope',
  //查询申报记录评分列表
  queryScoreList: base_url + '/system/score/newList',
  //查询申报记录评分明细列表
  queryScoreDetailList: base_url + '/system/detail/list',
  //查询申报记录详情
  queryScoreDetail: base_url + '/system/record/getApplyInfoById',
  //查询评分内容详情
  queryScoreInfo: base_url + '/system/score/getScoreInfo',
  //查询评分内容详情
  exportScore: base_url + '/system/score/export',
  openVoteNum: base_url + '/system/record/openVoteNum',
  //新增保存申报记录评分
  addScore: base_url + '/system/score/add',
  //批量修改参赛申请记录状态
  batchUpdateApplyStatus: base_url + '/system/record/batchUpdateApplyStatus',
  //批量修改参赛申请记录状态
  importApplyData: base_url + '/demo/operate/importApplyData',
  //排序
  sortApply: base_url + '/system/score/sortFinalApplyScore',
  //删除
  deleteRecord: base_url + '/system/record/remove',
  //历史查询列表
  queryHistoryList: base_url + '/system/score/historyList',
  //查询状态
  queryStateList: base_url + '/system/dict/data/stateList',
  //申报信息下载
  exportRecord: base_url + '/system/record/export',

  //前台接口
  // 新增参赛申请记录
  saveAddRecord: base_url + '/system/record/add',
  // 新增参赛申请记录
  saveEditRecord: base_url + '/system/record/edit',
  // 查询参赛申请记录
  getApplyInfo: base_url + '/system/record/getApplyInfo',
  // 查询参赛申请记录
  getOpenId: base_url + '/system/record/openIdMsg',
  // 查询参赛申请记录列表
  queryRecordList: base_url + '/system/record/list',
  // 查询赛道
  queryProgramTrack: base_url + '/system/dict/data/list',
  // 查询赛道
  wechatHanlde: base_url + '/wechat/messageHandle'

};