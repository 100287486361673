var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    { staticClass: "navbar", attrs: { mode: "horizontal" } },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "toggle-click": _vm.toggleSideBar,
          "is-active": _vm.sidebar.opened
        }
      }),
      _vm._v(" "),
      _c("breadcrumb"),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "avatar-container", attrs: { trigger: "click" } },
        [
          _c("div", { staticClass: "avatar-wrapper" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("i", { staticClass: "el-icon-caret-bottom" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            {
              staticClass: "user-dropdown",
              attrs: { slot: "dropdown" },
              slot: "dropdown"
            },
            [
              _c(
                "router-link",
                { staticClass: "inlineBlock", attrs: { to: "/" } },
                [
                  _c("el-dropdown-item", [_vm._v("\n          Home\n        ")])
                ],
                1
              ),
              _vm._v(" "),
              _c("el-dropdown-item", { attrs: { divided: "" } }, [
                _c(
                  "span",
                  {
                    staticStyle: { display: "block" },
                    on: { click: _vm.logout }
                  },
                  [_vm._v("LogOut")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }