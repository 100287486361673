//
//
//
//
//
//
//
//
//
//
//

import pathToRegexp from 'path-to-regexp';

export default {
  data: function data() {
    return {
      levelList: null
    };
  },

  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },

  methods: {
    getBreadcrumb: function getBreadcrumb() {
      var params = this.$route.params;

      var matched = this.$route.matched.filter(function (item) {
        if (item.name) {
          // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
          var toPath = pathToRegexp.compile(item.path);
          item.path = toPath(params);
          return true;
        }
      });
      var first = matched[0];
      if (first && first.name !== '') {
        matched = [{ path: '/', meta: { title: '奇璞奖管理系统' } }].concat(matched);
      }
      this.levelList = matched;
    }
  }
};