//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import "@/styles/index.scss";
import ElContainer from "element-ui/packages/container/src/main";
import ElHeader from "element-ui/packages/header/src/main";
import ElAside from "element-ui/packages/aside/src/main";
import ElMain from "element-ui/packages/main/src/main";
import Cookies from "js-cookie";
import request from "@/utils/request";
import api from "@/api/list";

export default {
  name: "Container",
  components: { ElMain: ElMain, ElAside: ElAside, ElHeader: ElHeader, ElContainer: ElContainer },
  data: function data() {
    return {
      isCollapse: false,
      isAdmin: null,
      username: null,
      userInfo: null,
      role: null,
      activeIndex: "0-0-0",

      roles: {
        admin: "管理员",
        operator: "操作员"
      },
      treeData: []
    };
  },

  created: function created() {
    this.username = sessionStorage.getItem("userName");
    this.userInfo = null;
    this.role = Cookies.get("role");
    this.getTreeData();
  },
  methods: {
    getTreeData: function getTreeData() {
      var _this = this;

      var urls = ["/examination", "/examination/reExamine", "/examination/finalExamine", "/examination/seed", "/examination/qipu", "/manage/user", "/manage/role", "/history", "/manage/project"];
      request.get(api.queryMenuTreeData).then(function (res) {
        var list = res || [];
        var firstUrl = "";
        var lists = list.filter(function (item) {
          if (item.url) {
            var url = item.url.indexOf("?") > -1 ? item.url.split("?")[0] : item.url;
            if (urls.indexOf(url) > -1) {
              return item;
            }
          } else {
            return item;
          }
        });
        var treeData = [];
        lists.map(function (item) {
          if (item.pId == 0) {
            item.list = [];
            treeData.push(item);
          }
        });
        treeData.forEach(function (v) {
          lists.map(function (item) {
            if (item.pId != 0 && item.pId == v.id && item.menuType == "C") {
              if (!firstUrl) {
                firstUrl = item.url;
              }
              v.list.push(item);
            }
          });
        });
        var newData = [{
          checked: false,
          id: 100,
          list: [],
          menuType: "C",
          name: "奇璞种子奖",
          nocheck: false,
          open: false,
          pId: 0,
          title: "奇璞种子奖",
          url: ""
        }, {
          checked: false,
          id: 100,
          list: [],
          menuType: "C",
          name: "奇璞奖",
          nocheck: false,
          open: false,
          pId: 0,
          title: "奇璞奖",
          url: ""
        }];
        treeData.map(function (tree) {
          if (tree.title != "账号设置" && tree.title != "历史项目查询") {
            if (/^30.*/g.test(tree.id)) {
              newData[0].list.push(tree);
            }
            if (/^4.*/g.test(tree.id)) {
              newData[1].list.push(tree);
            }
          } else {
            newData.push(tree);
          }
        });

        console.log(treeData, newData, 88993);
        _this.treeData = newData;
        if (firstUrl) {
          _this.navigateTo(firstUrl);
        }
      }).catch(function (err) {});
    },
    handleLoginOut: function handleLoginOut() {
      var _this2 = this;

      request.post(api.loginOut).then(function () {
        _this2.$router.push({
          path: "/login"
        });
      });
    },
    handleCommand: function handleCommand(command) {
      switch (command) {
        case "logout":
          this.logout();
          break;
        default:
          break;
      }
    },
    navigateTo: function navigateTo(val) {
      // this.$router.push({ path: val})
      this.$router.push(val);
    },
    handleNav: function handleNav() {
      this.isCollapse = this.isCollapse === false ? true : false;
    },
    handleOpen: function handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose: function handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout: function logout() {
      this.$message.success("注销成功");
      Cookies.remove("admin_name");
      Cookies.remove("admin_id");
      Cookies.remove("role");
      this.$router.push("/login");
    }
  }
};