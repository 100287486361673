import _extends from 'babel-runtime/helpers/extends';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';

export default {
  data: function data() {
    return {
      item: {
        path: '/user',
        component: Layout,
        meta: { title: '客户管理', icon: 'tree' },
        children: [{
          path: '',
          name: 'Guest',
          component: function component() {
            return import('@/views/guest/index');
          },
          meta: { title: '客户管理', icon: 'tree' }
        }]
      }
    };
  },

  components: { SidebarItem: SidebarItem },
  computed: _extends({}, mapGetters(['sidebar']), {
    routes: function routes() {
      return this.$router.options.routes;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};