import Vue from "vue";
import Router from "vue-router";
import Cookie from "js-cookie";
import { Message } from "element-ui";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";
import Container from "../views/layout/Container";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
export var constantRouterMap = [{
  path: "/login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true
}, { path: "/404", component: function component() {
    return import("@/views/404");
  }, hidden: true },

// {
//   path: '/',
//   component: Layout,
//   redirect: '/home',
//   name: 'Dashboard',
//   hidden: true,
//   children: [{
//     path: 'dashboard',
//     component: () => import('@/views/dashboard/index')
//   }]
// },
{
  path: "/",
  component: Container,
  redirect: "/examination",
  meta: { requireAuth: true },
  children: [{ path: "", name: "Preliminary", component: function component() {
      return import("@/views/examination/preliminary");
    } }, {
    path: "/detail",
    name: "Detail",
    hidden: true,
    component: function component() {
      return import("@/views/home/detail");
    }
  }]
}, {
  path: "/examination",
  component: Container,
  meta: { requireAuth: true },
  children: [{
    path: "",
    name: "Preliminary",
    hidden: true,
    component: function component() {
      return import("@/views/examination/preliminary");
    }
  }, {
    path: "reExamine",
    name: "reExamine",
    hidden: true,
    component: function component() {
      return import("@/views/examination/reExamine");
    }
  }, {
    path: "finalExamine",
    name: "finalExamine",
    hidden: true,
    component: function component() {
      return import("@/views/examination/finalExamine");
    }
  }, {
    path: "seed",
    name: "seed",
    hidden: true,
    component: function component() {
      return import("@/views/examination/seed");
    }
  }, {
    path: "qipu",
    name: "qipu",
    hidden: true,
    component: function component() {
      return import("@/views/examination/seed");
    }
  }, {
    path: "detail",
    name: "detail",
    hidden: true,
    component: function component() {
      return import("@/views/examination/detail");
    }
  }]
}, {
  path: "/manage",
  component: Container,
  meta: { title: "账号管理", icon: "tree", requireAuth: true },
  children: [{
    path: "user",
    name: "Guest",
    component: function component() {
      return import("@/views/guest/index");
    },
    meta: { title: "账号管理", icon: "tree" }
  }, {
    path: "project",
    name: "project",
    component: function component() {
      return import("@/views/guest/project");
    },
    meta: { title: "项目设置", icon: "tree" }
  }, {
    path: "role",
    name: "role",
    component: function component() {
      return import("@/views/guest/role");
    },
    meta: { title: "角色管理", icon: "tree" }
  }]
}, {
  path: "/history",
  component: Container,
  meta: { title: "账号管理", icon: "tree", requireAuth: true },
  children: [{
    path: "",
    name: "history",
    component: function component() {
      return import("@/views/examination/history");
    },
    meta: { title: "奇璞奖项目报名记录", icon: "tree" }
  }]
}, {
  path: "/list",
  component: function component() {
    return import("@/views/record/list");
  },
  meta: { title: "奇璞奖项目报名记录", icon: "tree", requireAuth: true }
}, {
  path: "/report",
  name: "report",
  component: function component() {
    return import("@/views/record/report");
  },
  meta: { title: "奇璞奖项目申报填写表", icon: "tree" }
}, {
  path: "/add",
  name: "add",
  component: function component() {
    return import("@/views/record/add");
  },
  meta: {
    title: "奇璞奖项目申报填写表",
    icon: "tree",
    requireAuth: true
  }
}, {
  path: "/addStep",
  name: "addStep",
  component: function component() {
    return import("@/views/record/addStep");
  },
  meta: {
    title: "奇璞奖项目申报填写表",
    icon: "tree",
    requireAuth: true
  }
}, {
  path: "/addSelf",
  name: "addSelf",
  component: function component() {
    return import("@/views/record/addSelf");
  },
  meta: { title: "奇璞奖项目指数自评", icon: "tree" }
}, {
  path: "/recordDetail",
  name: "recordDetail",
  component: function component() {
    return import("@/views/record/detail");
  },
  meta: { title: "奇璞奖项目申报详情页", icon: "tree" }
}, {
  path: "/recordSuccess",
  name: "recordSuccess",
  component: function component() {
    return import("@/views/record/success");
  },
  meta: { title: "奇璞奖项目申报填写表", icon: "tree" }
}, {
  path: "/weixin",
  name: "weixin",
  component: function component() {
    return import("@/views/weixin/index");
  },
  meta: { title: "微信授权", icon: "tree" }
  // { path: "*", redirect: "/404", hidden: true }
}];
var router = new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: function scrollBehavior() {
    return { y: 0 };
  },
  routes: constantRouterMap
});

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
// router.beforeEach((to, from, next) => {
// if(to.matched.some((r)=> r.meta.requireAuth )){
//   if(Cookie.get('admin_id')){
//     next();
//   }else{
//     const mess = "请先登录";
//     Message({
//       message: mess,
//       type: 'warning',
//       duration: 5 * 1000
//     })
//     next({
//       path:'/login',
//       query:{redirect: to.fullPath}
//     });
//   }
// }else next();
// });

export default router;