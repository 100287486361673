import store from '@/store';

var _document = document,
    body = _document.body;

var WIDTH = 1024;
var RATIO = 3;

export default {
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        store.dispatch('CloseSideBar', { withoutAnimation: false });
      }
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.isMobile();
    if (isMobile) {
      store.dispatch('ToggleDevice', 'mobile');
      store.dispatch('CloseSideBar', { withoutAnimation: true });
    }
  },

  methods: {
    isMobile: function isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - RATIO < WIDTH;
    },
    resizeHandler: function resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.isMobile();
        store.dispatch('ToggleDevice', isMobile ? 'mobile' : 'desktop');

        if (isMobile) {
          store.dispatch('CloseSideBar', { withoutAnimation: true });
        }
      }
    }
  }
};