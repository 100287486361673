import _Promise from 'babel-runtime/core-js/promise';
import axios from 'axios';
import Cookie from 'js-cookie';
import router from '../router/router';
import Qs from 'qs';
import { Message } from 'element-ui';

// 创建axios实例
var service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },

  // headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  transformRequest: [function (data) {
    console.log(data);
    if (!data) {
      data = {};
    }
    // data.maxRedirects = 0
    console.log(data);
    data = Qs.stringify(data, { indices: false });
    return data;
  }],
  timeout: 5000 // 请求超时时间
});
service.defaults.withCredentials = true;
// service.interceptors.request.use(
//   request => {
// console.log(request)
//   }
// )


// response 拦截器
service.interceptors.response.use(function (response) {
  var res = response.data;
  // if(res.code!==1000){
  //   Message({
  //     message: res.message,
  //     type: 'error',
  //     duration: 2 * 1000
  //   })
  // }
  // if(res.code===1100){
  //   Cookie.remove("admin_id")
  //   Cookie.remove("admin_name")
  //   Cookie.remove("session_id")
  //   Cookie.remove("role")
  //   router.push('/login');

  // }
  return res;
}, function (error) {
  console.log(error); // for debug
  // console.log(error.response.status) // for debug
  // Message({
  //   message: error.message,
  //   type: 'error',
  //   duration: 2 * 1000
  // })
  // router.push('/');
  return _Promise.reject(error);
});

export default service;