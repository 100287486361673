var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { attrs: { id: "container" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-vertical-demo",
                  attrs: {
                    "text-color": "#fff",
                    "active-text-color": "#fff",
                    "default-active": "0-0-0",
                    collapse: _vm.isCollapse
                  },
                  on: { open: _vm.handleOpen, close: _vm.handleClose }
                },
                _vm._l(_vm.treeData, function(tree, index) {
                  return _c(
                    "el-submenu",
                    { key: tree.id, attrs: { index: index + "" } },
                    [
                      _c("template", { slot: "title" }, [
                        tree.title == "奇璞种子奖"
                          ? _c("img", {
                              attrs: {
                                src: require("../../assets/jiangxiang01.png")
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        tree.title == "奇璞奖"
                          ? _c("img", {
                              attrs: {
                                src: require("../../assets/jiangxiang02.png")
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        tree.title == "账号设置"
                          ? _c("img", {
                              attrs: {
                                src: require("../../assets/zhanghao.png")
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        tree.title == "历史项目查询"
                          ? _c("img", {
                              attrs: {
                                src: require("../../assets/history.png")
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(tree.title))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-menu-item-group",
                        [
                          tree.title == "历史项目查询"
                            ? _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "history" },
                                  on: {
                                    click: function($event) {
                                      _vm.navigateTo(tree.url)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(tree.title))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(tree.list, function(item, idx) {
                            return _c(
                              "el-submenu",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      item.list &&
                                      item.list.length > 0 &&
                                      tree.title != "历史项目查询",
                                    expression:
                                      "\n                item.list &&\n                item.list.length > 0 &&\n                tree.title != '历史项目查询'\n              "
                                  }
                                ],
                                key: item.id + index + "-" + idx,
                                attrs: { index: index + "-" + idx }
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _vm._v(_vm._s(item.title))
                                ]),
                                _vm._v(" "),
                                _vm._l(item.list, function(v, idx1) {
                                  return _c(
                                    "el-menu-item",
                                    {
                                      key:
                                        v.id + index + "-" + idx + "-" + idx1,
                                      attrs: {
                                        index: index + "-" + idx + "-" + idx1
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.navigateTo(v.url)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(v.title))]
                                  )
                                })
                              ],
                              2
                            )
                          }),
                          _vm._v(" "),
                          _vm._l(tree.list, function(item, idx) {
                            return _c(
                              "el-menu-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !item.list ||
                                      (item.list &&
                                        item.list.length == 0 &&
                                        tree.title != "历史项目查询"),
                                    expression:
                                      "!item.list || (item.list && item.list.length == 0) && tree.title != '历史项目查询'"
                                  }
                                ],
                                key: item.id + index + "" + idx,
                                attrs: { index: index + "" + idx },
                                on: {
                                  click: function($event) {
                                    _vm.navigateTo(item.url)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.title) +
                                    "\n            "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    2
                  )
                })
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-main",
            [
              _c("el-header", [
                _c("span", [
                  _c("img", {
                    attrs: { src: require("../../assets/wenzi.png") }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "info", on: { click: _vm.handleLoginOut } },
                  [
                    _c("span", [_vm._v("您好，" + _vm._s(_vm.username))]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: { src: require("../../assets/tuichu.png") }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page-container" },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }